import { async } from "@firebase/util";
import { createSlice } from "@reduxjs/toolkit";
// utils
import apiUrls from "../../config/api-urls";
import { ApiHelper } from "../api-helpers";

// ----------------------------------------------------------------------

const initialState = {
  fetchCompanydone: false,
  isCompanyDone: false,
  isCompanySnack: 0,
  isBranchDone: false,
  isEmployeeDone: null,
  isIncidentDone: false,
  isIncidentByDateDone: false,
  isIncidentByCamDone: false,
  isIncidentcomDone: false,
  company_photo: null,
  companyData: null,
  branch: null,
  branches: null,
  companies: null,
  branchData: [],
  err_msg: null,
  current_page: null,
  incident: null,
  incidents: [],
  incidentId: [],
  incidentcam: null,
  incidentreviewcom: null,
  isIncidentUpdateDone: false,
  cashierData: null,
  cashiers: [],
  cashierId: [],
  cashier_photo: null,
  isCashierUpdateDone: false,
  isCashierDone: false,
  products: [],
  productData: null,
  productId: [],
  product_photo: null,
  isProductUpdateDone: false,
  isProductDone: false,
  nextprevData: [],
  isNextPrevDone: false,
  isOpenModal: false,
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // SUCCESS
    companyPhotoUpload(state, action) {
      state.isCompanyDone = true;
      state.company_photo = action.payload.photo;
    },
    // SUCCESS
    companySuccess(state, action) {
      state.company_errors = null;
      state.isCompanyDone = true;
      state.isCompanySnack += 1;
      state.err_msg = null;
      state.companyData = action.payload.companyData;
    },
    companyUnmount(state) {
      state.isCompanySnack = 0;
    },

    // SUCCESS
    fetchCompanySuccess(state, action) {
      state.company = action.payload.company;
    },
    // HAS ERROR
    fetchCompanyError(state, action) {
      state.fetchCompanydone = false;
      state.company = null;
      state.err_msg = action.payload.err_msg;
    },
    // SUCCESS
    incidentByDateSuccess(state, action) {
      state.incidents = action.payload.incidents;
      state.err_msg = null;
      state.isIncidentByDateDone = true;
    },
    // HAS ERROR
    incidentByDateError(state, action) {
      state.isIncidentByDateDone = false;
      state.incidents = null;
      state.err_msg = action.payload.err_msg;
    },

    // SUCCESS
    updateIncidentSuccess(state, action) {
      state.err_msg = null;
      state.isIncidentUpdateDone = true;
    },
    // Pending
    updateIncidentPending(state, action) {
      state.err_msg = action.payload.err_msg;
      state.isIncidentUpdateDone = false;
    },
    // HAS ERROR
    updateIncidentError(state, action) {
      state.isIncidentUpdateDone = false;
      state.err_msg = action.payload.err_msg;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },

    // SUCCESS
    incidentSuccess(state, action) {
      state.incident = action.payload.incident;
      state.err_msg = null;
      state.isIncidentDone = true;
    },
    // HAS ERROR
    incidentError(state, action) {
      state.isIncidentDone = false;
      state.incident = null;
      state.err_msg = action.payload.err_msg;
    },
    // SUCCESS
    incidentIdSuccess(state, action) {
      state.incidentId = action.payload.incidentId;
      state.err_msg = null;
    },
    // HAS ERROR
    incidentIdError(state, action) {
      state.incidentId = null;
      state.err_msg = action.payload.err_msg;
    },
    // SUCCESS
    IncidentCommSuccess(state, action) {
      state.incidentreviewcom = action.payload.incidentreviewcom;
      state.err_msg = null;
      state.isIncidentcomDone = true;
    },
    // HAS ERROR
    IncidentCommError(state, action) {
      state.isIncidentcomDone = false;
      state.incidentreviewcom = null;
      state.err_msg = action.payload.err_msg;
    },
    // HAS ERROR
    companyError(state, action) {
      state.isCompanyDone = false;
      state.company_photo = null;
      state.err_msg = action.payload.err_msg;
    },

    // SUCCESS
    branchSuccess(state, action) {
      state.branch = action.payload.branch;
      state.err_msg = null;
      state.isBranchDone = true;
    },
    // HAS ERROR
    branchError(state, action) {
      state.isBranchDone = false;
      state.err_msg = action.payload.err_msg;
    },
    // SET Current Page
    setCurrentPage(state, action) {
      state.current_page = action.payload.page;
    },

    // SUCCESS
    fetchBranchesSuccess(state, action) {
      state.branches = action.payload.branches;
      state.err_msg = null;
      // state.isBranchDone = true;
    },

    // SUCCESS
    fetchCompaniesSuccess(state, action) {
      state.companies = action.payload.companies;
      state.err_msg = null;
      state.isCompanyDone = true;
    },

    // SUCCESS
    fetchBranchSuccess(state, action) {
      state.branch = action.payload.branch;
    },

    // SUCCESS
    fetchBranchUidSuccess(state, action) {
      state.branchData = action.payload.branchData;
    },
    // HAS ERROR
    fetchBranchError(state, action) {
      state.isBranchDone = false;
      state.branch = null;
      // state.err_msg = action.payload.err_msg;
    },

    // HAS ERROR
    fetchBranchUidError(state, action) {
      state.branchData = null;
      // state.err_msg = action.payload.err_msg;
    },

    // SUCCESS
    cashierSuccess(state, action) {
      state.cashiers = action.payload.cashiers;
      state.err_msg = null;
      // state.isCashierDone = true;
    },
    // HAS ERROR
    cashierError(state, action) {
      // state.isCashierDone = false;
      state.cashiers = null;
      state.err_msg = action.payload.err_msg;
      state.cashier_photo = null;
    },
    // SUCCESS
    cashierIdSuccess(state, action) {
      state.cashierId = action.payload.cashierId;
      state.err_msg = null;
    },
    // HAS ERROR
    cashierIdError(state, action) {
      state.cashierId = null;
      state.err_msg = action.payload.err_msg;
    },

    // SUCCESS
    createCashierSuccess(state, action) {
      state.isCashierDone = true;
      state.err_msg = null;
      state.cashierData = action.payload.cashierData;
    },
    // HAS ERROR
    createCashierError(state, action) {
      state.cashierData = null;
      state.err_msg = action.payload.err_msg;
    },

    cashierPhotoUpload(state, action) {
      state.isCashierDone = true;
      state.cashier_photo = action.payload.photo;
    },
    updateIsCashierDone(state, action) {
      state.isCashierDone = action.payload;
    },

    // SUCCESS
    productSuccess(state, action) {
      state.products = action.payload.products;
      state.err_msg = null;
    },
    // HAS ERROR
    productError(state, action) {
      state.products = null;
      state.err_msg = action.payload.err_msg;
      state.product_photo = null;
    },
    // SUCCESS
    productIdSuccess(state, action) {
      state.productId = action.payload.productId;
      state.err_msg = null;
    },
    // HAS ERROR
    productIdError(state, action) {
      state.productId = null;
      state.err_msg = action.payload.err_msg;
    },

    // SUCCESS
    createProductSuccess(state, action) {
      state.isProductDone = true;
      state.err_msg = null;
      state.productData = action.payload.productData;
    },
    // HAS ERROR
    createProductError(state, action) {
      state.productData = null;
      state.err_msg = action.payload.err_msg;
    },

    productPhotoUpload(state, action) {
      state.isProductDone = true;
      state.product_photo = action.payload.photo;
    },
    updateIsProductDone(state, action) {
      state.isProductDone = action.payload;
    },

    // SUCCESS
    fetchNextPrevSuccess(state, action) {
      state.isNextPrevDone = true;
      state.err_msg = null;
      state.nextprevData = action.payload.nextprevData;
    },
    // HAS ERROR
    fetchNextPrevError(state, action) {
      state.nextprevData = null;
      state.err_msg = action.payload.err_msg;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  companySuccess,
  companyError,
  fetchCompanySuccess,
  fetchCompanyError,
  branchSuccess,
  branchError,
  fetchBranchesSuccess,
  fetchBranchSuccess,
  fetchBranchError,
  incidentSuccess,
  incidentError,
  incidentByDateSuccess,
  incidentByDateError,
  IncidentCommSuccess,
  IncidentCommError,
  defaultBranchSuccess,
  defaultBranchError,
  incidentIdSuccess,
  incidentIdError,
  updateIsBranchDone,
  companyUnmount,
  updateIncidentSuccess,
  updateIncidentPending,
  updateIncidentError,
  cashierSuccess,
  cashierError,
  cashierIdSuccess,
  cashierIdError,
  createCashierSuccess,
  createCashierError,
  cashierPhotoUpload,
  updateIsCashierDone,
  productSuccess,
  productError,
  productIdSuccess,
  productIdError,
  createProductSuccess,
  createProductError,
  productPhotoUpload,
  updateIsProductDone,
  openModal,
  closeModal,
} = slice.actions;

///CHANGE HERE
export function postCompany(data, cid) {
  return async (dispatch) => {
    await ApiHelper({
      url: cid
        ? `${apiUrls.POST_COMPANY_URL}/${cid}`
        : apiUrls.POST_COMPANY_URL,
      // url: apiUrls.COMPANY_API_URL,
      data: data,
      method: cid ? "PUT" : "POST",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.companySuccess({
            companyData: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.companyError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function postCompanyPhoto(data, comId) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.COMPANY_PHOTO_UPLOAD}/${comId}`,
      data: data,
      image: true,
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.companyPhotoUpload({
            photo: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.companyError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function employeeNextpage(data) {
  return async (dispatch) => {
    dispatch(
      slice.actions.employeeNext({
        employeeBool: data,
      })
    );
  };
}

export function fetchIncident(bid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.ALLINCIDENT_FETCH_URL}/${bid}`,
      method: "GET",
    })
      .then((response) => {
        const apiRes = response.data ? response.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.incidentSuccess({
            incident: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.incidentError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}
export function fetchIncidentId(id, data = null) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.INCIDENT_URL}/${id}`,
      data: data,
      method: data ? "PUT" : "GET",
    })
      .then((response) => {
        const apiRes = response.data ? response.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.incidentIdSuccess({
            incidentId: response.data.data || data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.incidentIdError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function updatetempIncident(data, pricemismatchdata) {
  return async (dispatch) => {
    if (pricemismatchdata && pricemismatchdata.length > 0) {
      let tempdata = JSON.parse(JSON.stringify(data));
      tempdata.pos["Price Mismatch Details"] = JSON.stringify(
        pricemismatchdata
      ).replace(/\"/g, "'");

      dispatch(
        slice.actions.incidentIdSuccess({
          incidentId: tempdata,
        })
      );
    }
  };
}

export function updateIncidentParams(tempdata) {
  return async (dispatch) => {
    dispatch(
      slice.actions.incidentIdSuccess({
        incidentId: tempdata,
      })
    );
  };
}

export function fetchIncidentbyDate(dayInput, data) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.INCIDENT_FETCHBY_DATE_URL}/${dayInput}`,
      data: data,
      method: "PUT",
    })
      .then((response) => {
        const apiRes = response.data ? response.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.incidentByDateSuccess({
            incidents: response.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.incidentByDateError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function fetchAllIncident(data) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.INCIDENT_FETCH_ALL}`,
      data: { limit: 7000, page: 0 },
      method: "POST",
    })
      .then((response) => {
        const apiRes = response.data.result ? response.data.result : null;
        if (!apiRes && response.data.result) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.incidentByDateSuccess({
            incidents: response.data.result,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.incidentByDateError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

//new Apis for new dashboard design

export function SaveIncidentComment(data, tid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.INCIDENT_URL}/${tid}`,
      data: data,
      method: "PUT",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.IncidentCommSuccess({
            incidentreviewcom: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.IncidentCommError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function fetchBranches(data) {
  return async (dispatch) => {
    await ApiHelper({
      url: apiUrls.FETCH_BRANCH_API_URL,
      // method: 'GET',
      // data: data,
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;

        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.fetchBranchesSuccess({
            branches: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.fetchBranchError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function fetchBranch(bid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.BRANCH_API_URL}/${bid}`,
      method: "GET",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.fetchBranchSuccess({
            branch: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.fetchBranchError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function fetchCompany(cid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.COMPANY_API_URL}/${cid}`,
      method: "GET",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.fetchCompanySuccess({
            company: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.fetchCompanyError({
            err_msg:
              apiResErr.error || "fetchCompany"
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function setCurrentPage(currPageId) {
  return async (dispatch) => {
    return dispatch(
      slice.actions.setCurrentPage({
        page: currPageId,
      })
    );
  };
}

export function fetchCompanies(data) {
  return async (dispatch) => {
    await ApiHelper({
      url: apiUrls.FETCH_COMPANY_API_URL,
      data: data,
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.fetchCompaniesSuccess({
            companies: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.fetchCompanyError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

// export function fetchIncidentbyDate(dayInput, data) {
//   return async (dispatch) => {
//     dispatch(slice.actions.incidentByDatePending({}));
//     await ApiHelper({
//       url: `${apiUrls.INCIDENT_FETCHBY_DATE_URL}/${dayInput}`,
//       data: data,
//       method: "PUT",
//     })
//       .then((response) => {
//         const apiRes = response.data ? response.data : null;
//         if (!apiRes && response.data) {
//           throw TypeError(response.data.message);
//         }
//         dispatch(
//           slice.actions.incidentByDateSuccess({
//             incidents: response.data,
//           })
//         );
//       })
//       .catch((apiResErr) => {
//         dispatch(
//           slice.actions.incidentByDateError({
//             err_msg:
//               apiResErr.error || apiResErr.message
//                 ? apiResErr.message
//                 : "Something went wrong",
//           })
//         );
//       });
//   };
// }

export function updateIncident(data, uid = null) {
  return async (dispatch) => {
    dispatch(slice.actions.updateIncidentPending({}));
    await ApiHelper({
      url: `${apiUrls.UPDATE_INCIDENT_URL}/${uid}`,
      data: data,
      method: "PUT",
    })
      .then((response) => {
        const apiRes = response.data ? response.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.updateIncidentSuccess({
            incidents: response.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.updateIncidentError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function fetchAllCashiers(data) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.READ_ALL_CASHIERS_URL}`,
      data: { limit: 1000, page: 0 },
      method: "GET",
    })
      .then((response) => {
        const apiRes = response.data.data ? response.data.data : null;
        if (!apiRes && response.data.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.cashierSuccess({
            cashiers: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.cashierError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function fetchCashierId(id, data = null) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.READ_ALL_CASHIERS_URL}/${id}`,
      data: data,
      method: data ? "PUT" : "GET",
    })
      .then((response) => {
        const apiRes = response.data.data ? response.data.data : null;

        if (!apiRes && response.data.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.cashierIdSuccess({
            cashierId: response.data.data || data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.cashierIdError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function postCashier(data, cid) {
  return async (dispatch) => {
    await ApiHelper({
      url: cid
        ? `${apiUrls.CREATE_CASHIER_URL}/${cid}`
        : apiUrls.CREATE_CASHIER_URL,
      data: data,
      method: cid ? "PUT" : "POST",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.createCashierSuccess({
            cashierData: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.createCashierError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function postCashierPhoto(data, cashierId) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.CASHIER_PHOTO_UPLOAD}/${cashierId}`,
      data: data,
      image: true,
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.cashierPhotoUpload({
            photo: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.cashierError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function fetchAllProducts(data) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.READ_ALL_PRODUCTS_URL}`,
      data: { limit: 5000, page: 0 },
      method: "GET",
    })
      .then((response) => {
        const apiRes = response.data.data ? response.data.data : null;
        if (!apiRes && response.data.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.productSuccess({
            products: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.productError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function fetchProductId(id, data = null) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.READ_ALL_PRODUCTS_URL}/${id}`,
      data: data,
      method: data ? "PUT" : "GET",
    })
      .then((response) => {
        const apiRes = response.data.data ? response.data.data : null;

        if (!apiRes && response.data.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.productIdSuccess({
            productId: response.data.data || data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.productIdError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function postProducts(data, pid) {
  return async (dispatch) => {
    await ApiHelper({
      url: pid
        ? `${apiUrls.CREATE_PRODUCT_URL}/${pid}`
        : apiUrls.CREATE_PRODUCT_URL,
      data: data,
      method: pid ? "PUT" : "POST",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.createProductSuccess({
            productData: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.createProductError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function postProductPhoto(data, productId) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.PRODUCT_PHOTO_UPLOAD}/${productId}`,
      data: data,
      image: true,
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.productPhotoUpload({
            photo: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.productError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function postNextPrevData(data) {
  return async (dispatch) => {
    await ApiHelper({
      url: apiUrls.NEXT_PREV_URL,
      data: data,
      method: "POST",
    })
      .then((response) => {
        const apiRes = response.data ? response.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.fetchNextPrevSuccess({
            nextprevData: response.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.fetchNextPrevError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}
